import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from 'src/hooks/use-user';
import useCart from 'src/hooks/use-cart';

import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';
import { ADS_FETCH_POLICY, useAdInventoryId } from 'src/utils/ads/helpers';
import { SponsoredBrandQuery } from 'src/utils/ads/types';
import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { useGetPersonalizedProductsQuery, useGetSponsoredBrandQuery } from 'types/graphql';

type UseProductsForHomePageParams = {
  dispensaryId: string;
};

export type UseProductsForHomePageReturn = {
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery: SponsoredBrandQuery;
};

export function useProductsForHomePage({ dispensaryId }: UseProductsForHomePageParams): UseProductsForHomePageReturn {
  const user = useUser();
  const cart = useCart();
  const flags = useFlags();
  const menuType = useObserver(() => cart.menuType);
  const isLoggedIn = useObserver(() => user.isLoggedIn);
  const isRecMenu = menuType === `rec` || menuType === `both`;

  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('home-page');
  const hasPersonalizationEnabled = flags['growth.ads.personalization-carousel.placement'] !== 'none';
  const isSponsoredContentEnabledForPersonalization =
    flags['growth.ads.enable-sponsored-content-personalization.rollout'];

  const alwaysSkip = !dispensaryId || !isRecMenu;
  const skipPersonalizedProducts = alwaysSkip || !hasPersonalizationEnabled;
  const skipSponsoredProducts =
    (skipPersonalizedProducts || !isSponsoredContentEnabledForPersonalization) && !hasValidSponsoredBrandPlacement;

  const inventoryId = useAdInventoryId(dispensaryId);
  const personalizedProductLimit = isLoggedIn ? 20 : 3;

  const personalizedProductsQuery = useGetPersonalizedProductsQuery({
    context: { useBatch: true },
    fetchPolicy: `network-only`,
    skip: skipPersonalizedProducts,
    variables: {
      input: {
        dispensaryId,
        limit: personalizedProductLimit,
        filter: {
          menuType,
        },
      },
    },
  });

  const sponsoredBrandQuery = useGetSponsoredBrandQuery({
    context: { useBatch: true },
    fetchPolicy: ADS_FETCH_POLICY,
    skip: skipSponsoredProducts,
    variables: {
      input: {
        dispensaryId,
        inventoryId,
        maximumProducts: 20,
        minimumProducts: 3,
      },
    },
  });

  return {
    personalizedProductsQuery: _.pick(personalizedProductsQuery, ['data', 'loading', 'error']),
    sponsoredBrandQuery: _.pick(sponsoredBrandQuery, ['data', 'loading', 'error']),
  };
}
